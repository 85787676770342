import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const baseInputStyles = css`
  display: block;
  width: 100%;
  min-height: 41px;
  border: none;
  border-bottom: 2px solid ${({ theme }) => rgba(theme.light ? theme.colors.bg : theme.colors.secondary, 0.5)};
  background: transparent;
  appearance: none;
  transition: border .2s;
  color: ${({ theme }) => (theme.light ? theme.colors.bg : theme.colors.primary)};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.05em;

  &:focus {
    outline: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledInput = styled.input`${baseInputStyles}`;

const StyledTextArea = styled.textarea`${baseInputStyles}`;

const Label = styled.label`
  color: ${({ theme }) => (theme.light ? theme.colors.bg : theme.colors.primary)};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;
`;

const CheckboxLabel = styled(Label)`
  display: block;
  padding-left: 32px;
  font-weight: 300;

  &:before {
    content: '';
    margin-left: -32px;
    margin-right: 16px;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: bottom;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    border: 2px solid ${({ theme }) => rgba(theme.light ? theme.colors.bg : theme.colors.secondary, 0.5)};
  }
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  &:focus + ${Label}:before {
    border-color: ${({ theme }) => theme.colors.secondary}
  }

  &:checked + ${Label}:before {
    content: '\u2713';
  }
`;

const Error = styled.div`
  min-height: 32px;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: tomato;

  :not(:empty) {
    padding: 8px 0 16px ${(p) => (p.checkbox ? '32px' : 0)};
  }
`;

export const Checkbox = ({
  field,
  form: { errors },
  label,
  ...props
}) => (
  <>
    <StyledCheckbox id={field.name} className="hideVisually" checked={field.value} {...field} {...props} />
    <CheckboxLabel htmlFor={field.name}>
      {label()}
    </CheckboxLabel>
    <Error checkbox>
      {errors[field.name]}
    </Error>
  </>
);

export const Button = styled.button`
  display: block;
  appearance: none;
  min-width: 256px;
  line-height: 60px;
  /* min-height: 60px; */
  margin: 0 auto;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 18px;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.bg};
  background: ${({ theme }) => theme.colors.secondary};
  border: 0;

  &:disabled {
    background: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }
`;

export const TextArea = ({
  field,
  form: { errors },
  label,
  ...props
}) => (
  <>
    <Label htmlFor={field.id}>
      {label}
      <StyledTextArea {...field} {...props} />
    </Label>
    <Error>
      {errors[field.name]}
    </Error>
  </>
);

export default ({
  field,
  form: { errors },
  label,
  ...props
}) => (
  <>
    <Label htmlFor={field.id}>
      {label}
      <StyledInput type="text" {...field} {...props} />
    </Label>
    <Error>
      {errors[field.name]}
    </Error>
  </>
);
