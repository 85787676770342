import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Row = styled.div`
  position: relative;
  display: inherit;
  width: calc(100% + 30px);
  flex-wrap: wrap;
  align-items: ${props => props.align || 'flex-start'};
  justify-content: ${props => props.justify || 'flex-start'};
  margin-right: -15px;
  margin-left: -15px;

  ${up('lg')} {
    flex-wrap: ${props => props.wrap || 'nowrap'};
  }
`;

export default Row;
