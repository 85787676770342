import React from 'react';

const Icon = ({
  className,
  fill,
  height,
  icon,
  stroke,
  width,
}) => (
  <svg
    className={className}
    fill={fill}
    height={height}
    stroke={stroke}
    viewBox={icon.viewBox}
    width={width}
  >
    <use xlinkHref={icon.url} />
  </svg>
);

export default Icon;
