import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import Container from './container';
import NonStyledIcon from './icon';
import Link from './link';
import Row from './row';
import { SectionBase } from './section';

import mdk from '../assets/icons/partners/mdk.svg';
import prokultura from '../assets/icons/partners/prokultura.svg';

import pomorskie from '../assets/icons/partners/pomorskie.svg';
import czluchow from '../assets/icons/partners/czluchow.svg';

const Icon = styled(NonStyledIcon)`
  display: flex;
  margin: 30px 15px;

  ${up('lg')} {
    margin: 7.5px 15px;
  }
`;

const Sponsors = () => (
  <SectionBase>
    <Container>
      <Row align="center" justify="space-between">
        <Link to="http://czluchow.naszdomkultury.pl/">
          <Icon icon={mdk} height={75} />
        </Link>
        <Icon icon={prokultura} height={70} />
        <Link to="https://pomorskie.eu/">
          <Icon icon={pomorskie} width={210} height={70} />
        </Link>
        <Link to="https://czluchow.eu/">
          <Icon icon={czluchow} width={140} height={70} />
        </Link>
      </Row>
    </Container>
  </SectionBase>
);

export default Sponsors;
