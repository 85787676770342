import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';

const gutter = '15px';

const columnWith = (size = 0) => `${100 / 12 * size}%`;

const Column = styled.div`
  padding-left: ${gutter};
  padding-right: ${gutter};
  background: ${({ bg, theme }) => (bg ? theme.colors[bg] : 'transparent')};

  ${down('md')} {
    width: 100%;
  }

  ${up('md')} {
    display: flex;
    flex: 1;
    flex-direction: ${({ direction }) => direction || 'column'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
    ${p => (p.max ? 'width: 100%' : '')};
  }

  ${up('lg')} {
    margin-left: ${p => (p.offset ? columnWith(p.offset) : 0)};
  }
`;

export const ColumnSmall = styled(Column)`
  ${up('md')} {
    max-width: 30%;
  }
`;

export default Column;
