import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  flex-wrap: wrap;

  ${up('md')} {
    display: flex;
  }

  ${up('xl')} {
    width: ${p => (p.fluid ? '100%' : 'calc(100vw - 300px)')};
  }
`;

export default Container;
