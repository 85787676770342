import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { up } from 'styled-breakpoints';
import { hideVisually, fluidRange } from 'polished';
import {
  Field,
  Form,
  Formik,
} from 'formik';

import StyledInput, { Button, TextArea } from './styled-form';
import Column from './column';
import Container from './container';
// import Cta from './cta';
import Icon from './icon';
import Link from './link';
import Heading from './heading';
// import Menu, { MenuLink } from './menu';
import Row from './row';
import { Section } from './section';
import Sponsors from './sponsors';

// import menu from '../assets/icons/menu.svg';
// import X from '../assets/icons/x.svg';
import logo from '../assets/icons/logo.svg';
import themeConfig, { colors } from '../configs/theme';

const paragraphFluid = (theme) => fluidRange(
  [
    {
      prop: 'font-size',
      fromSize: '16px',
      toSize: '22px',
    },
    {
      prop: 'margin-bottom',
      fromSize: '24px',
      toSize: '33px',
    },
  ],
  theme.breakpoints.sm,
  theme.breakpoints.xxl,
);

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    font-family: 'Helvetica', sans-serif;
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.bg};

    .wf-active & {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: 300;
    }

    &.ovh {
      overflow: hidden;
      min-height: 100%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  .ifc {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .if {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
  }

  button {
    background: none;
    border: none;
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  .hideVisually {
    ${hideVisually()};
  }

  p {
    max-width: 640px;
    margin: 0;
    padding: 0;
    line-height: 2;

    ${({ theme }) => paragraphFluid(theme)}
  }

  .orangeFill {
    fill: ${({ theme }) => theme.colors.secondary};
  }

  .blueStroke {
    fill: none;
    stroke: ${({ theme }) => theme.colors.primary};
    stroke-miterlimit: 10;
    stroke-width: 4px;
  }

  .hero-video {
    .plyr__video-wrapper, .plyr--video {
      background: ${({ theme }) => theme.colors.secondary};
    }

     .plyr__poster {
       background-color: ${({ theme }) => theme.colors.secondary};
     }
  }
`;

const Document = styled.div`
  display: flex;
  height: 100vh;
  margin-top: 64px;
  flex-direction: column;

  ${up('md')} {
    margin-top: 96px;
  }

  ${up('xl')} {
    margin-top: 0;
  }
`;

const Main = styled.main`
  flex: auto;
`;

const NavBar = styled.div`
  display: flex;
  top: 0;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  z-index: 10;
  background: ${({ theme }) => theme.colors.bg};
  pointer-events: none;

  ${up('md')} {
    height: 96px;
  }

  ${up('xl')} {
    height: 135px;
    background: transparent;
  }

  .container {
    display: flex;
    height: 100%;
    padding: 5px 15px;
    pointer-events: all;
    background: ${({ theme }) => theme.colors.bg};
    flex-direction: row-reverse;
    align-content: center;

    ${up('xl')} {
      flex-direction: column;
      justify-content: center;
    }

    a {
      display: block;
    }

    &.logo svg {
      width: 54px;
      height: 54px;

      ${up('md')} {
        width: 66px;
        height: 66px;
      }

      ${up('xl')} {
        width: 100px;
        height: 100px;
      }
    }

    ${up('md')} {
      padding: 15px;
    }
  }

  .trigger {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    svg {
      margin-right: 10px;
    }

    &.secondary {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.secondary};
      margin-right: 10px;

      ${up('xl')} {
        margin-right: 0;
      }
    }
  }  
`;

// const Footer = styled.footer`
//   padding: 60px 0;
//   flex-shrink: 0;
//   background: ${({ theme }) => theme.colors.third};

//   .logo {
//     display: block;
//     // margin-bottom: 30px;
//   }
// `;

const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

class Layout extends PureComponent {
  // state = {
  //   isMenuOpen: false,
  // };

  toggleMenu = () => {
    this.setState((state) => ({
      ...state,
      isMenuOpen: !state.isMenuOpen,
    }));
  }

  render() {
    const {
      props: { data, location, ...props },
      // state: { isMenuOpen },
    } = this;

    return (
      <Document>
        <NavBar>
          <div className="container logo">
            <Link
              to="/"
              // onClick={() => {
              //   this.toggleMenu();
              // }}
            >
              <Icon icon={logo} width="100" height="100" fill={colors.primary} />
            </Link>
          </div>
        </NavBar>
        <Main>
          {props.children}
        </Main>
        <Sponsors />
        <Section>
          <Container>
            <Row>
              <Column offset={2}>
                <Heading as="h2">Let's keep in touch!</Heading>
              </Column>
            </Row>
            <Row align="center">
              <Column offset={2}>
                <Formik
                  initialValues={{
                    email: '', name: '', message: '', 'bot-field': '', 'form-name': 'expo-contact',
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'E-mail is required.';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'E-mail is wrong.';
                    }

                    if (!values.name) {
                      errors.name = 'Name is required.';
                    }

                    if (!values.message) {
                      errors.message = 'Message is required.';
                    }

                    return errors;
                  }}
                  onSubmit={(values, actions) => {
                    fetch('/', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                      body: encode(values),
                    })
                      .then(() => {
                        actions.resetForm();
                        actions.setStatus({
                          message: 'Your message was submitted.',
                        });
                      })
                      .catch(() => {
                        actions.setStatus({
                          message: 'An error occurred during sending a message.',
                        });
                      })
                      .finally(() => actions.setSubmitting(false));
                  }}
                >
                  {({ isSubmitting, status }) => (
                    <Row name="expo-contact" as={Form} data-netlify netlify-honeypot="bot-field">
                      <Column style={{ flex: 2 }}>
                        <Field name="bot-field" type="hidden" />
                        <Field name="form-name" type="hidden" />
                        {
                          status?.message && (
                            <Row>
                              <Column><p>{status.message}</p></Column>
                            </Row>
                          )
                        }
                        <Row>
                          <Column>
                            <Field type="text" name="name" label="NAME" component={StyledInput} />
                          </Column>
                          <Column style={{ flex: 2 }}>
                            <Field type="e-mail" name="email" label="E-MAIL ADDRESS" component={StyledInput} />
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <Field
                              name="message"
                              component={TextArea}
                              label="YOUR MESSAGE"
                              rows={5}
                              columns={40}
                            />
                          </Column>
                        </Row>
                      </Column>
                      <Column>
                        <Button type="submit" disabled={isSubmitting}>
                          Send a message
                        </Button>
                      </Column>
                    </Row>
                  )}
                </Formik>
              </Column>
            </Row>
          </Container>
        </Section>
        {/* <Footer>
          <Container>
            <Row>
              <Column style={{ flex: 2 }}>
                <Link to="/" className="logo">
                  <Icon icon={logo} width="100" height="100" fill={colors.bg} />
                </Link>
              </Column>
            </Row>
          </Container>
        </Footer> */}
      </Document>
    );
  }
}

const LayoutWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => {
      const { title } = props;
      const pageTitle = [data.site.siteMetadata.title];

      if (title) {
        pageTitle.push(title);
      }

      return (
        <ThemeProvider theme={themeConfig}>
          <>
            <Helmet>
              <html lang="pl" />
              <title>{ pageTitle.reverse().join(' | ') }</title>
              <meta name="description" content="Festiwal, który porywa nie tylko do tańca. Już od 22. do 24. lipca 2021 zapraszamy do Człuchowa." />
            </Helmet>
            <GlobalStyle />
            <Location>
              { (locationProps) => <Layout {...locationProps} {...props} data={data} /> }
            </Location>
          </>
        </ThemeProvider>
      );
    }}
  />
);

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWrapper;
