import styled from 'styled-components';
import { up } from 'styled-breakpoints';
// import { fluidRange } from 'polished';

// const fluidSpaces = (theme, prop) => fluidRange(
//   [
//     {
//       prop: `${prop}-top`,
//       fromSize: '32px',
//       toSize: '128px',
//     },
//     {
//       prop: `${prop}-bottom`,
//       fromSize: '32px',
//       toSize: '128px',
//     },
//   ],
//   theme.breakpoints.sm,
//   theme.breakpoints.lg,
// );

export const SectionHalfBg = styled.section`
  ${up('lg')} {
    background-image: linear-gradient(to right, transparent, transparent 50%, ${({ theme }) => theme.colors.primary} 50%, ${({ theme }) => theme.colors.primary} 100%);
  }
`;

export const SectionBase = styled.section`
  background-repeat: no-repeat;
  padding-top: 32px;
  padding-bottom: 32px;

  ${up('md')} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  ${up('lg')} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  ${up('xl')} {
    padding-top: 128px;
    padding-bottom: 128px;
  }
`;

export const Section = styled(SectionBase)`
  background-image: url(/images/section-bg.svg);
  background-size: 15%;
  background-repeat: no-repeat;
  background-position-y: 16px;

  ${up('md')} {
    background-position-y: 24px;
  }

  ${up('lg')} {
    background-position-y: 42px;
  }

  ${up('xl')} {
    background-position-y: 58px;
  }
`;

export default Section;
