export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1680px',
};

export const colors = {
  primary: '#223974',
  secondary: '#F49922',
  third: '#333333',
  bg: '#FAF4E8',
};

export const fonts = {
  primary: 'Oswald, sans-serif',
};

export default {
  breakpoints,
  colors,
  fonts,
};
