import styled from 'styled-components';
import { fluidRange } from 'polished';

const minSizes = {
  h1: 42,
  h2: 24,
  h3: 18,
  h3: 14,
};

const maxSizes = {
  h1: 72,
  h2: 48,
  h3: 28,
  h4: 22,
};

const Heading = styled.h2`
  font-weight: ${({ weight }) => weight || 500};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.primary)};

  ${({ theme, as, mt = false }) => fluidRange(
    [
      {
        prop: 'font-size',
        fromSize: `${minSizes[as] || minSizes.h2}px`,
        toSize: `${maxSizes[as] || maxSizes.h2}px`,
      },
      {
        prop: 'margin-bottom',
        fromSize: `${(minSizes[as] || minSizes.h2) * 0.75}px`,
        toSize: `${(maxSizes[as] || maxSizes.h2) * 0.75}px`,
      },
      ...(mt ? [{
        prop: 'margin-top',
        fromSize: `${(minSizes[as] || minSizes.h2) * 0.75}px`,
        toSize: `${(maxSizes[as] || maxSizes.h2) * 0.75}px`,
      }] : []),
    ],
    theme.breakpoints.sm,
    theme.breakpoints.xxl,
  )}
`;

export default Heading;
